<template>
    <stage :content="content"/>
</template>

<script>
// @ is an alias to /src
import stageContent from '@/data/abgeber.js'
import stage from '@/components/stage.vue'

export default {
  name: 'Abgeber',
  components: {
    stage
  },
  data () {
    return {
      content: stageContent
    }
  }
}
</script>
