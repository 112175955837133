const baseUrl = 'https://assets.nl-kompetenzzentrum.de'
export default {
  slides: [
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6398.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6398.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6398.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6398.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6398.jpg`
      }
    },
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6412.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6412.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6412.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6412.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6412.jpg`
      }
    },
    {
      alt: 'Kompetenz­zentrum für Niederlassungs­beratung',
      full: `${baseUrl}/img/full/_DSC6369.jpg`,
      l: {
        '1x': `${baseUrl}/img/large/_DSC6369.jpg`
      },
      m: {
        '1x': `${baseUrl}/img/medium/_DSC6369.jpg`
      },
      s: {
        '1x': `${baseUrl}/img/small/_DSC6369.jpg`
      },
      xs: {
        '1x': `${baseUrl}/img/xsmall/_DSC6369.jpg`
      }
    }
  ],
  texts: [
    {
      href: `${baseUrl}/content/abgeber/p1.md`,
      button: [
        {
          label: 'Download Leistungskatalog',
          href: `${baseUrl}/downloads/Leistungskatalog_Praxisabgabe.pdf`
        },
        {
          label: 'Kontakt aufnehmen',
          href: 'mailto:info@nl-kompetenzzentrum.de'
        }
      ],
      breadcrumbs: [
        {
          label: 'Abgabe Praxisteile',
          slideLink: 1
        },
        {
          label: 'Aufnahme Partner',
          slideLink: 2
        }
      ]
    },
    {
      href: `${baseUrl}/content/abgeber/p2.md`,
      button: [
        {
          label: 'Download Leistungskatalog',
          href: `${baseUrl}/downloads/Leistungskatalog_Praxisabgabe.pdf`
        },
        {
          label: 'Kontakt aufnehmen',
          href: 'mailto:info@nl-kompetenzzentrum.de'
        }
      ],
      breadcrumbs: [
        {
          label: 'Abgabe Praxis',
          slideLink: 0
        },
        {
          label: 'Aufnahme Partner',
          slideLink: 2
        }
      ]
    },
    {
      href: `${baseUrl}/content/abgeber/p3.md`,
      button: [
        {
          label: 'Download Leistungskatalog',
          href: `${baseUrl}/downloads/Leistungskatalog_Praxisabgabe.pdf`
        },
        {
          label: 'Kontakt aufnehmen',
          href: 'mailto:info@nl-kompetenzzentrum.de'
        }
      ],
      breadcrumbs: [
        {
          label: 'Abgabe Praxis',
          slideLink: 0
        },
        {
          label: 'Abgabe Praxisteile',
          slideLink: 1
        }
      ]
    }
  ]
}
